/* Variables */
:root {
  --bg-color: #000000;
  --bg-secondary-color: #f3f3f6;
  --color-primary: #B3446C;
  --color-lightGrey: #d2d6dd;
  --color-grey: #747681;
  --color-darkGrey: #3f4144;
  --color-error: #C42C48;
  --color-success: #0B9924;
  --grid-maxWidth: 120rem;
  --grid-gutter: 2rem;
  --font-size: 1.6rem;
}

html {
  width: 100%;
  height: 100%; 
}

body {
  margin: 0px;
  width: 100%;
  height: 100%;
  background-color: black; 
}

.header-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
}

.header-spacer {
  flex-grow: 1;
}

.header-title {
  color: #C42C48;
  font-size: 2em;
  margin-top: 10px;
  background-color: black;
  border-radius: 5px;
  padding: 5px;
}

.header-subtitle {
  color: #B3446C;
  margin-top: 5px;
  background-color: black;
  border-radius: 5px;
  padding: 5px;
}

.header-card {
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 20px;
  border-radius: 10px;
  background-color: black;
}

.header-list-item {
  color: white;
}

.header-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}

.header-button {
  margin-left: 10px;
  margin-right: 10px;

  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: black !important;
}

/* Balls */
.ball-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -999;
}

.ball {
  display: block;
  background: var(--color-primary);
  border-radius: 100%;
  margin: 0;
  z-index: -999;
}

/* Footer */
.footer-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.footer {
  /* For future use */
}